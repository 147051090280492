@tailwind utilities;

@layer utilities {
  .default-reset-css {
    all: unset;
    display: block;
  }

  .default-reset-css h1,
  .default-reset-css h2,
  .default-reset-css h3,
  .default-reset-css h4,
  .default-reset-css h5,
  .default-reset-css h6,
  .default-reset-css p,
  .default-reset-css ol,
  .default-reset-css ul,
  .default-reset-css li,
  .default-reset-css figure,
  .default-reset-css blockquote {
    margin: initial;
    padding: initial;
  }

  .default-reset-css ol {
    list-style: decimal;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-left: 40px;
  }

  .default-reset-css ul {
    list-style: disc;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-left: 40px;
  }

  .default-reset-css li {
    list-style: inherit;
  }

  .default-reset-css blockquote {
    quotes: '“' '”' '‘' '’';
  }

  .default-reset-css q {
    quotes: '“' '”';
  }

  .default-reset-css table {
    border-collapse: collapse;
  }

  .default-reset-css caption {
    text-align: left;
    font-style: italic;
  }
}
